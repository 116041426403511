import { myDashboardActions } from ".";
import {
  getAllTopbarber_client_serviceService,
} from "./services";

export const getAllTopbarber_client_serviceAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(myDashboardActions.setIsFetching(true));
      const res = await getAllTopbarber_client_serviceService(query);
      if (res?.status === 200) {
        dispatch(myDashboardActions.setTopServices(res?.result));
        dispatch(myDashboardActions.setIsFetching(false));
      }
      dispatch(myDashboardActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};