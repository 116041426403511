import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

// Discount

export const createDiscountService = async (data) => {
  return await HttpRequest.post(`${SERVER_URL}/discount/add-discount`, data);
};

export const getAllDiscountsService = async (query) => {
  return HttpRequest.get(`${SERVER_URL}/discount/get-all-discounts${query || "?"}`);
};

export const updateDiscountService = async (itemId, data) => {
  return await HttpRequest.update(
    `${SERVER_URL}/discount/update-discount-item/${itemId}`,
    data
  );
};

export const deleteDiscountService = async (itemId) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/discount/delete-discount-item/${itemId}`
  );
};


// Subscription

export const createSubscriptionService = async (data) => {
  return await HttpRequest.post(`${SERVER_URL}/subsrpt/add`, data);
};

export const getAllSubscriptionsService = async (query) => {
  return HttpRequest.get(`${SERVER_URL}/subsrpt/get-all${query || "?"}`);
};

export const updateSubscriptionService = async (itemId, data) => {
  return await HttpRequest.update(
    `${SERVER_URL}/subsrpt/update-item/${itemId}`,
    data
  );
};

export const updateSubscriptionPriceService = async (itemId, data) => {
  return await HttpRequest.update(
    `${SERVER_URL}/subsrpt/update-price/${itemId}`,
    data
  );
};

export const deleteSubscriptionService = async (itemId) => {
  return await HttpRequest.delete(
    `${SERVER_URL}/subsrpt/delete-item/${itemId}`
  );
};

// Subscription Management

export const assignSubscriptionToClientService = async (data) => {
  return await HttpRequest.post(`${SERVER_URL}/subsrpt/management/add`, data);
};
export const getSubManagementService = async () => {
  return await HttpRequest.get(`${SERVER_URL}/subsrpt/management/get-all`);
};

//Attandance
export const getAttandanceService = async (query) => {
  return await HttpRequest.get(`${SERVER_URL}/attandance/get-all${query}`);
};


//Team Member
export const getAllUsersService = async (query) => {
  return await HttpRequest.get(`${SERVER_URL}/team${query}`);
};

export const getOneUsersService = async (itemId, query) => {
  return await HttpRequest.get(`${SERVER_URL}/team/${itemId}${query || "?"}`);
};

// export const getUserAvailabilityService = async (itemId, date) => {
//   return await HttpRequest.get(`${SERVER_URL}/appointment/time/${itemId}?appointment_date=${date}`);
// };

