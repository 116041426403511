import { Table } from "antd";
import React, { useState } from "react";

const DrowerTable = ({ dataToShow }) => {
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const handleRowExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKey(record);
    } else {
      setExpandedRowKey(null);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const dataToShowInTable = dataToShow?.subscriptionDetails?.map(
    (el, index) => {
      return {
        key: index,
        name: el?.name,
        amount: el?.amount,
        status: el?.isActive ? "Active" : "Inactive",
        history: el?.history,
      };
    }
  );

  const expandedRowRender = () => {
    const Expandedcolumns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
      },
      {
        title: "Consumption",
        dataIndex: "amountUsed",
        key: "amountUsed",
      },
    ];
    const innerData = expandedRowKey?.history?.map((d, index) => {
      return {
        key: index,
        date: d?.date?.slice(0, 10),
        amountUsed: d?.amount?.toLocaleString(),
      };
    });

    return (
      <Table
        columns={Expandedcolumns}
        dataSource={innerData}
        pagination={false}
      />
    );
  };

  return (
    <Table
      columns={columns}
      expandable={{
        expandedRowRender,
        defaultExpandedRowKeys: ["0"],
        onExpand: handleRowExpand,
        expandedRowKeys: [expandedRowKey?.key],
      }}
      dataSource={dataToShowInTable}
    />
  );
};

export default DrowerTable;
