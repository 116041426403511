/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Dashboard from "../../Dashboard";
import "../sales/index.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "../../Skeleton";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { FaFileExport } from "react-icons/fa";
import { getUser } from "../../utils/common";
import ReactPaginate from "react-paginate";
import { useMediaQuery } from "@mui/material";
import { getAllSubManagementAction } from "../../../store/settings/actions";
import { useDispatch, useSelector } from "react-redux";
import { calculator, getActiveSubscriptionDetail } from "../../../utils/helper";
import DrowerModel from "../../model/drowerModel";
import DrowerTable from "./clientComp/DrowerTable";

const Subscribers = () => {
  const { setting } = useSelector((state) => state)
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentpage] = useState(1);
  const [postsPerPage] = useState(10);
  const dispatch = useDispatch()

  const [dataToShow, setDataToShow] = useState(null)
  const [openDrower, setOpenDrower] = useState(false)

  const handleOpenDrower = (data) => {
    setDataToShow(data)
    setOpenDrower(true)
  }

  const handleCloseDrower = (data) => {
    setDataToShow(null)
    setOpenDrower(false)
  }

  const isTablet = useMediaQuery("(max-width: 960px)");

  const user = getUser();
  const findRole = user[0]?.roles[0]?.permissions;
  const permissions = [];
  findRole?.map((item) => permissions.push(item.permissions));

  useEffect(() => {
    getAllSubManagementAction()(dispatch)
  }, [dispatch])

  const filteredPosts = setting?.allSubManagement?.data?.filter((item) => {
    const searchLower = search.toLowerCase();
    return searchLower === ""
      ? item?.client?.hasSubscription === "Yes"
      : (item.client?.firstname.toLowerCase().includes(searchLower) ||
        item.client?.lastname.toLowerCase().includes(searchLower)) && item?.client?.hasSubscription === "Yes";
  });
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredPosts?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = ({ selected }) => {
    setCurrentpage(selected + 1);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const lowercaseValue = value.toLowerCase();
    setSearch(lowercaseValue);
  };

  return (
    <>
      <ToastContainer />
      <Dashboard>
        <div className={`content-body mt-[3rem] ${!isTablet && "ml-52"}`}>
          <div className="container-fluid">
            <div
              className={`relative form-head mb-3 flex items-center justify-between`}
            >
              {!isTablet && (
                <div className="mr-auto d-lg-block">
                  <h3 className="text-black font-w500 mb-6">Our Subscribers</h3>

                </div>
              )}
            </div>
            <div className="col-md-6 -ml-4 mb-4 flex justify-center md:justify-start">
              <input
                type="text"
                name="name"
                className="form-input w-full  py-2 px-4 rounded-md border border-solid border-[#d1d1d1] focus:border-[#e3b04b] focus:ring focus:ring-[#e3b04b]"
                value={search}
                onChange={handleInputChange}
                placeholder="Search by Client Name "
              />
            </div>
            <div className="row">
              <div className="col-12">
                {setting?.isFetching && <Skeleton />}
                {!setting?.isFetching && (
                  <div className="table-responsive">
                    <div className="card">
                      {permissions.indexOf("export-client") !== -1 &&
                        !isTablet && (
                          <div className=" absolute right-6 items-center justify-center top-3 flex gap-2">
                            <small>Export</small>
                            <ReactHTMLTableToExcel
                              id="test-table-xls-button"
                              className="btn btn-sm btn-primary light flex items-center svg-btn shadow-none z-15"
                              table="table-to-xls"
                              filename="Barber-Client"
                              sheet="Client List"
                              buttonText={<FaFileExport />}
                            />
                          </div>
                        )}
                      <div className="card-body mt-6">
                        <div className="table-responsive">
                          <table
                            id="table-to-xls"
                            className="display table-hover w-full"
                          >
                            <thead>
                              <tr className="border-b">
                                <th className="py-6">Date</th>
                                <th className="py-6">Client name</th>
                                <th className="py-6">Mobile number</th>
                                <th className="py-6">Membership</th>
                                <th className="py-6">Amount paid</th>
                                <th className="py-6">Remaining</th>
                                <th className="py-6">Status</th>
                                <th className="py-6">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {currentPosts
                                ?.filter((item) => {
                                  return search.toLowerCase() === ""
                                    ? item
                                    : item.client?.firstname
                                      .toLowerCase()
                                      .includes(search) ||
                                    item.client?.lastname
                                      .toLowerCase()
                                      .includes(search)
                                })
                                ?.map((item) => (
                                  <tr key={item._id} className="border-b">
                                    <td className="py-3 capitalize">
                                      {item?.createdAt?.slice(0, 10)}
                                    </td>
                                    <td className="py-3 capitalize">
                                      {`${item?.client?.firstname} ${item?.client?.lastname}`}
                                    </td>
                                    <td className="py-3">{item?.client?.telephone}</td>
                                    {!isTablet && (
                                      <td className="py-3 capitalize">
                                        {getActiveSubscriptionDetail(item).name}
                                      </td>
                                    )}
                                    <td className="py-3">{getActiveSubscriptionDetail(item)?.amount?.toLocaleString()}</td>
                                    <td className="py-3">{calculator(getActiveSubscriptionDetail(item)?.amount, getActiveSubscriptionDetail(item)?.usedAmount, "sub")?.toLocaleString() || 0}</td>
                                    <td className="py-3">{getActiveSubscriptionDetail(item)?.isActive ? "Active" : "Expired"}</td>
                                    <td className="py-3">
                                      {" "}
                                      {permissions.indexOf("view-client") !==
                                        -1 && (
                                          <button
                                            className="bg-gray-900 hover:bg-[#cf7500] rounded shadow btn-xs sharp mr-1 bell bell-link"
                                            onClick={() => handleOpenDrower(item)}
                                          >
                                            <i className="fa fa-eye text-white"></i>
                                          </button>
                                        )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <ReactPaginate
                        onPageChange={paginate}
                        pageCount={Math.ceil(setting?.allSubManagement?.data.length / postsPerPage)}
                        previousLabel={"Prev"}
                        nextLabel={"Next"}
                        containerClassName={"pagination"}
                        pageLinkClassName={"page-number"}
                        previousLinkClassName={"page-number"}
                        nextLinkClassName={"page-number"}
                        activeLinkClassName={"active"}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
      <DrowerModel
        title={`${dataToShow?.client?.firstname} ${dataToShow?.client?.lastname}'s History`}
        open={openDrower}
        handleClose={handleCloseDrower}
        components={<DrowerTable dataToShow={dataToShow} />}
      />
    </>
  );
};

export default Subscribers;
