/* eslint-disable no-useless-concat */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { toast, ToastContainer } from "react-toastify";
import { axiosRequest, refreshPage } from "../../../../api";
import { getUser } from "../../../utils/common";
import LoadingButton from "../../../LoadingButton";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCartRequestActions,
  getOneCartRequestActions,
  removeToCartRequestActions,
} from "../../../../store/cart/actions";
import { calculateDueDate, calculateTotalAmount, getActivePrice, getServiceAmount, getServiceIdWithCount10 } from "../../../../utils/helper";
import ConfirmModel from "../../../model/confirmModel";
import { Button, Divider, Modal, Select, notification } from "antd";
import Empty from '../../../../assets/images/Empty-cart.png';
import { completeAppointmentActions } from "../../../../store/appointment/actions";
import { getAllClientClientAction, updateClientActions } from "../../../../store/client/actions";
import moment from "moment";
import { assignSubscriptionToClientAction } from "../../../../store/settings/actions";
import CreateClientForm from "../../../form/createClientForm";
// import "../../../pages/sales/index.css";
const Client_URL = "client";
const Team_URL = "team?excludeTypes=ADMIN_A,CASHIER_C";


const { Option } = Select;

const Invoice = ({ isTablet }) => {
  const { cart, setting, client } = useSelector((state) => state);
  const { cartId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const todayDate = moment().format("YYYY-MM-DD");

  const [removeProductModel, setRemoveProductModel] = useState(false);
  const [removeServiceModel, setRemoveServiceModel] = useState(false);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [removeSubModel, setRemoveSubModel] = useState(false);
  const [productId, setProductId] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [serviceToDelete, setServiceToDelete] = useState("");
  const [subscriptionToremove, setSubscriptionToremove] = useState("")
  const [hasAppointmentId, setHasAppointmentId] = useState("");
  const [Data, setData] = useState([]);
  const [enableLoading, setEnableLoading] = useState()

  const [deleDataModel, setDeleteDataModel] = useState(false);
  const [appointment, setAppointment] = useState([]);
  const [loading, setLoading] = useState(true);
  const id = appointment[0]?._id;
  const [open, setOpen] = useState(false);
  const handleDropDown = () => setOpen(!open);

  const user = getUser();
  const findRole = user[0]?.roles[0]?.permissions;
  const permissions = [];
  findRole?.map((item) => permissions.push(item.permissions));

  const clientServices = cart?.selected?.data?.client?.subscription?.reference?.services

  const serviceWithBonus = getServiceIdWithCount10(cart?.selected?.data?.client?.bonusManagement?.clientHistory)
  const bonusAmount = getServiceAmount(cart?.selected?.data?.client?.bonusManagement?.clientHistory, cart?.selected?.data?.listOfService)

  const deleteModel = () => {
    let newState = !deleDataModel;
    setDeleteDataModel(newState);
  };
  const getTeamMember = () => {
    setLoading(true);
    axiosRequest
      .get(Team_URL)
      .then((response) => {
        setLoading(false);
        const result = response.data.data;
        setData(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const getClients = () => {
    getAllClientClientAction()(dispatch);
  };

  const handleRejectStatus = () => {
    const url = `appointment/cancel/${id}`;
    axiosRequest
      .put(url)
      .then((response) => {
        const result = response.data;
        const { status, message } = result;
        toast.success("Appointment rejected");
        setTimeout(() => {
          refreshPage();
        }, 500);
      })
      .catch((error) => {
        console.log(error);
        setTimeout(() => {
          refreshPage();
        }, 2000);
      });
  };

  useEffect(() => {
    getClients();
    getTeamMember();
  }, []);

  const isEmptyCart =
    cart?.selected?.data?.products?.length === 0 &&
    cart?.selected?.data?.listOfService?.length === 0 && !cart?.selected?.data?.Subscription

  const totalAmount = calculateTotalAmount(cart?.selected?.data ?? {}, clientServices);
  const totalDisc = (totalAmount - (cart?.selected?.data?.discount * totalAmount / 100)) + getActivePrice(
    cart?.selected?.data?.Subscription?.sub_amount
  )

  const showProductModal = (value) => {
    setRemoveProductModel(true);
    setProductId(value);
  };

  const showClientModal = () => {
    setIsClientModalOpen(true)
  }

  const handleCancel = () => {
    setIsClientModalOpen(false)
  }

  const hideProductModal = () => {
    setRemoveProductModel(false);
    setProductId("");
  };

  const showSubModal = (value) => {
    setRemoveSubModel(true);
    setSubscriptionToremove(value);
  };

  const hideSubModal = () => {
    setRemoveSubModel(false);
    setSubscriptionToremove("");
  };

  const showServiceModal = (value, service) => {
    setRemoveServiceModel(true);
    setServiceId(service?._id);
    setServiceToDelete(service?.servicename)
    setHasAppointmentId(value);
  };
  const hideServiceModal = () => {
    setRemoveServiceModel(false);
    setServiceToDelete("")
    setServiceId("")
  };

  const handleRemoveProduct = async () => {
    await removeToCartRequestActions(
      cartId,
      {
        productIdsToRemove: [productId],
      }, "Removed from the cart"
    )(dispatch);
    setProductId("");
    setRemoveProductModel(false);
  };

  const handleRemoveSubscription = async () => {
    await removeToCartRequestActions(
      cartId,
      {
        removeSubscription: subscriptionToremove,
      },
      "Removed from the cart"
    )(dispatch);
    setSubscriptionToremove("");
    setRemoveProductModel(false);
  };

  const handleRemoveService = async () => {
    await removeToCartRequestActions(
      cartId,
      { serviceIdsToRemove: [serviceId] }, `Removed from the cart`
    )(dispatch);
    setServiceId("");
    setServiceToDelete("")
    setRemoveServiceModel(false);
  };

  const handlerUpdateCart = async (field, value) => {
    setEnableLoading(field)
    await addToCartRequestActions(cartId, {
      [field]: value,
    }, `${field} added Successfully`)(dispatch);
  }

  const productToSell = cart?.selected?.data?.products?.map((d) => {
    return {
      productId: d?.productId?._id,
      quantity: d?.quantity,
    };
  });

  const handleCartPayment = async () => {
    setEnableLoading("done")
    const payload = {
      subscriptionId: cart?.selected?.data?.Subscription?._id,
      clientId: cart?.selected?.data?.client?._id,
    }
    if (cart?.selected?.data?.hasSubscription) {
      const res = await assignSubscriptionToClientAction(payload)(dispatch)
      if (res) {
        const isSold = await addToCartRequestActions(cartId, {
          amoutPaid: cart?.selected?.data?.discount > 0 ? totalDisc : totalAmount,
          status: "paid",
          productsToAdd: productToSell,
          bonusAllowed: bonusAmount
        }, "Done Successfully")(dispatch);
        if (isSold) {
          navigate(`/sales/details/${cartId}`);
        }
      }
    } else {
      const isSold = await addToCartRequestActions(cartId, {
        amoutPaid: cart?.selected?.data?.discount > 0 ? totalDisc : totalAmount,
        status: "paid",
        productsToAdd: productToSell,
        bonusAllowed: bonusAmount
      }, "Done Successfully")(dispatch);
      if (isSold) {
        navigate(`/sales/details/${cartId}`);
      }
    }

  };

  const clientOptions =
    client?.all?.data?.map((el) => ({
      value: el?._id,
      label: `${el?.firstname && el?.lastname
        ? `${el?.firstname} ${el?.lastname}`
        : el?.firstname || el?.lastname}`,
    })) || [];

  return (
    <>
      <ToastContainer />
      {/* ====================== Start::  deleteDataModel =============================== */}
      <Fade right>
        <div
          className={`min-h-full -mt-[27rem] -ml-[70%] w-screen z-50 bg-opacity-30 backdrop-blur-sm fixed flex items-center justify-center px-4 ${deleDataModel === true ? "block" : "hidden"
            }`}
        >
          <div className="bg-white w-1/2 shadow-2xl rounded-lg p-4">
            <div className="card-title w-full flex  flex-wrap justify-center items-center  ">
              <h1 className="font-bold text-sm text-center w-11/12">
                Reject Appointment
              </h1>
              <hr className=" bg-primary border-b w-full" />
            </div>
            <div className="card-body">
              <form className=" px-8">
                <div>
                  <h2 className="text-base pb-4 text-center">
                    Do you really want to Reject this Appointment??
                  </h2>
                </div>
                <div className="w-full flex justify-between">
                  <button
                    className="btn-white  shadow-none"
                    onClick={(e) => deleteModel(e.preventDefault())}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn-black btn-s shadow-none"
                    onClick={handleRejectStatus}
                  >
                    Reject
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fade>
      {/* =========================== End::  deleteDataModel =============================== */}

      <Fragment>
        <div className="card">
          <div className="card-body">
            <h4 className="d-flex justify-content-between align-items-center mb-3">
              <span className="text-muted">Cart</span>
            </h4>
            <div>
              <span>Discount</span>
              <Select
                className="w-100 h-10"
                id="automatic-selection"
                value={cart?.selected?.data?.discount || ""}
                onChange={(value) => handlerUpdateCart("discount", value)}

              >
                {setting?.all?.data?.map((el, index) => (
                  <Option
                    value={el?.percentage}
                    key={index}
                    className="capitalize"
                  >
                    {el?.name}
                  </Option>
                ))}
                <Option
                  value={0}
                  className="capitalize"
                >
                  none
                </Option>
              </Select>
            </div>
            <hr />
            {!isEmptyCart && (
              <ul className="list-group mb-3">
                {cart?.selected?.data?.products?.length > 0 &&
                  cart?.selected?.data?.products?.map((d) => (
                    <>
                      <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 className="my-0">{d?.productId?.productname}</h6>
                        </div>
                        <div className="flex items-center space-x-4">
                          <span className="text-muted">
                            {`${d?.quantity
                              } x ${d?.productId?.amount?.toLocaleString()} RWF`}
                          </span>
                          <spa
                            onClick={() => showProductModal(d?.productId?._id)}
                          >
                            <i className="fa fa-trash-o text-red-500"></i>
                          </spa>
                        </div>
                      </li>
                      {removeProductModel && (
                        <ConfirmModel
                          name={d?.productId?.productName}
                          open={removeProductModel}
                          hideModal={hideProductModal}
                          onOk={handleRemoveProduct}
                          isLoading={cart?.isFetching}
                        />
                      )}
                    </>
                  ))}

                {
                  cart?.selected?.data?.listOfService?.length > 0 &&
                  cart?.selected?.data?.listOfService?.map((d) => (
                    <>
                      <li className="list-group-item d-flex justify-content-between lh-condensed">
                        <div className="flex flex-col">
                          <span className="fs-14"><strong>{d?.servicename}</strong></span>
                          {serviceWithBonus?.includes(d?._id) ? <small className="border w-28 rounded-md bg-green-400 text-center">Bunus Allowed</small> : ""}
                        </div>
                        <div className="flex items-center space-x-4">
                          <span
                            className="text-black"
                            style={{
                              textDecoration: "none",
                              color:
                                clientServices &&
                                  clientServices?.includes(d?._id)
                                  ? "gray"
                                  : "inherit",
                            }}
                          >
                            {`${parseInt(d?.amount)?.toLocaleString()} Rwf`}
                          </span>

                          <span onClick={() => showServiceModal(d?._id, d)}>
                            <i className="fa fa-trash-o text-red-500"></i>
                          </span>
                        </div>
                      </li>
                      {removeServiceModel && (
                        <ConfirmModel
                          name={serviceToDelete}
                          open={removeServiceModel}
                          hideModal={hideServiceModal}
                          onOk={handleRemoveService}
                          isLoading={cart?.isFetching}
                        />
                      )}
                    </>
                  ))}

                {cart?.selected?.data?.Subscription && <>
                  <li className="list-group-item d-flex justify-content-between lh-condensed">
                    <div>
                      <span className="fs-14"><strong>{cart?.selected?.data?.Subscription?.name}</strong></span>
                      <small className="d-block fs-12">{cart?.selected?.data?.Subscription?.services?.length} Services Included</small>
                    </div>
                    <div className="flex items-center space-x-4">
                      <span className="text-black">{`${parseInt(
                        getActivePrice(
                          cart?.selected?.data?.Subscription?.sub_amount
                        )
                      )?.toLocaleString()} Rwf`}</span>

                      <span onClick={() => showSubModal(cart?.selected?.data?.Subscription?._id)}>
                        <i className="fa fa-trash-o text-red-500"></i>
                      </span>
                    </div>
                  </li>
                  {removeSubModel && (
                    <ConfirmModel
                      name={cart?.selected?.data?.Subscription?.name}
                      open={removeSubModel}
                      hideModal={hideSubModal}
                      onOk={handleRemoveSubscription}
                      isLoading={cart?.isFetching}
                    />
                  )}
                </>}

                {(cart?.selected?.data?.listOfService?.length > 0 ||
                  cart?.selected?.data?.products?.length > 0) && (
                    <>
                      <li className="list-group-item flex flex-col space-y-1">
                        {cart?.selected?.data?.listOfService?.length > 0 &&
                          <div className="flex items-center justify-between">
                            <span className="">Sub Total</span>
                            <span>{`${totalAmount?.toLocaleString()} RWF`}</span>
                          </div>
                        }
                        {/* {cart?.selected?.data?.products?.length > 0 && <div className="flex items-center justify-between">
                          <span className="">Products Sub Total</span>
                          <span>{`${totalAmount?.toLocaleString()} RWF`}</span>
                        </div>} */}
                        {cart?.selected?.data?.Subscription && <div className="flex items-center justify-between">
                          <span className="">Membership</span>
                          <span>{`${getActivePrice(
                            cart?.selected?.data?.Subscription?.sub_amount
                          )?.toLocaleString()} RWF`}</span>
                        </div>}
                        <div className="flex items-center justify-between">
                          <span className="f">Discount</span>
                          <span>{`${cart?.selected?.data?.discount}%`}</span>
                        </div>
                        <div className="flex items-center justify-between">
                          <span className="font-semibold">Total Amount</span>
                          <strong>{`${cart?.selected?.data?.discount > 0 ? totalDisc?.toLocaleString() : (totalAmount - bonusAmount)?.toLocaleString()} RWF`}</strong>
                        </div>
                      </li>
                    </>
                  )}
              </ul>
            )}
            {isEmptyCart && <div className="flex justify-center items-center w-full mb-3"><img src={Empty} alt="empty cart" className='w-20 h-20' />
            </div>}
            {/* <form> */}
            <hr />
            <div className="row">
              <div className="col-md-4">
                <label className=" text-black font-w500 text-sm">
                  Client
                </label>
                <Select
                  className="w-100 h-10"
                  showSearch
                  placeholder="Search by names"
                  optionFilterProp="label"
                  options={clientOptions}
                  value={cart?.selected?.data?.client?.firstname ?? ""}
                  onChange={(value) => handlerUpdateCart("client", value)}
                  required
                  disabled={cart?.isUpdating}
                  loading={cart?.isUpdating && enableLoading === "client"}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <hr />
                      <Button
                        disabled={cart?.isUpdating}
                        style={{
                          backgroundColor: "#f0a907",
                          color: "white",
                          textAlign: "center",
                          width: "100%",
                        }}
                        onClick={showClientModal}
                        className="bg-[#f0a907] h-10 text-base text-white text-center w-full rounded-md"
                      >
                        + Add New Client
                      </Button>
                    </div>
                  )}
                />

              </div>
              <div className="col-md-4">
                <label className=" text-black font-w500 text-sm">
                  Payment Method
                </label>
                <Select
                  className="w-100 h-10"
                  id="automatic-selection"
                  value={cart?.selected?.data?.paymentMethod ?? ""}
                  onChange={(value) => handlerUpdateCart("paymentMethod", value)}
                  required
                  disabled={cart?.isUpdating}
                  loading={cart?.isUpdating && enableLoading === "paymentMethod"}
                >
                  {["Momo", "Card", "Cash", "Subscription"].map((el, index) => (
                    <Option value={el} key={index} className="capitalize">
                      {el}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="col-md-4">
                <label className=" text-black font-w500 text-sm">
                  Barber
                </label>
                <Select
                  className="w-100 h-10"
                  id="automatic-selection"
                  value={cart?.selected?.data?.barber?._id ?? ""}
                  onChange={(value) => handlerUpdateCart("barber", value)}
                  required
                  disabled={cart?.isUpdating}
                  loading={cart?.isUpdating && enableLoading === "barber"}
                >
                  {Data.map((el, index) => (
                    <Option value={el?._id} key={index} className="capitalize">
                      {`${el?.firstname} ${el?.lastname}`}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <br />

            <hr className="mb-4" />
            <div className="row">
              {/* <div className="col-md-3 text-sm">
                {permissions.indexOf("approve-sales") !== -1 && (
                  <button
                    className="btn-white  rounded border-2 text-sm "
                    type="button"
                    onClick={handleDropDown}
                  >
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                      </g>
                    </svg>
                  </button>
                )}
              </div> */}

              {permissions.indexOf("approve-sales") !== -1 && (
                <div className="col-md-9 text-sm flex items-center justify-center mx-auto">
                  {cart?.isUpdating && enableLoading === "done" ? (
                    <LoadingButton />
                  ) : (

                    <button
                      className={`btn-black text-sm btn-block ${(!cart?.selected?.data?.paymentMethod &&
                        !cart?.selected?.data?.client &&
                        !cart?.selected?.data?.barber) ||
                        (cart?.selected?.data?.products?.length < 1 && cart?.selected?.data?.listOfService?.length < 1 && !cart?.selected?.data?.hasSubscription)
                        ? "cursor-not-allowed"
                        : ""
                        }`}
                      disabled={
                        (!cart?.selected?.data?.paymentMethod &&
                          !cart?.selected?.data?.client &&
                          !cart?.selected?.data?.barber) ||
                        (cart?.selected?.data?.products?.length < 1 && cart?.selected?.data?.listOfService?.length < 1 && !cart?.selected?.data?.hasSubscription)

                      }
                      onClick={handleCartPayment}
                    >
                      {` Approve Sales`}
                    </button>
                  )}
                </div>
              )}
            </div>
            {/* </form> */}
          </div>
          {!isTablet && (
            <div
              className={
                !open
                  ? "hidden"
                  : "w-40 rounded-lg border bg-red-500 justify-end buttom-0"
              }
              onClick={handleDropDown}
            >
              <ul className="space-y-3 p-2">
                <li className="font-medium">
                  <button
                    className="flex cursor-pointer text-white items-center transform transition-colors duration-200 border-transparent"
                    onClick={() => {
                      deleteModel();
                    }}
                  >
                    Reject Appointment
                  </button>
                </li>
              </ul>
            </div>
          )}
        </div>
      </Fragment>
      <Modal
        title={
          <h1 className="text-[#030229] text-lg py-2">Add a new client</h1>
        }
        width={800}
        open={isClientModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <CreateClientForm onCancel={handleCancel} />
      </Modal>
    </>
  );
};

export default Invoice;
