import { notification } from "antd";
import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/14barber.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { axiosRequest } from "../../api";
import { setUserSession } from "../utils/common";
import Fade from "react-reveal/Fade";
import axios from "axios";
import AttandancePopUp from "../AttandancePopUp";

const Login_URL = "team/login";

export default function App() {

  const [askAdminPermissionModel, setAskAdminPermissionModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ipAddress, setIpAddress] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const navigate = useNavigate();
  const location = useLocation();

  const isAttendancePage = location.pathname === "/attendance";

  const adminPermission = () => {
    let newState = !askAdminPermissionModel;
    setAskAdminPermissionModel(newState);
  };

  const handleCancel = () => {
    setIsModalOpen(false)
    setEmail("")
    setPassword("")
  }


  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);
  //ADMIN_A
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axiosRequest
      .post(Login_URL, {
        email: email,
        password: password,
        ipAddress: ipAddress
      })
      .then((res) => {
        setLoading(false);
        const result = res.data;
        const { message } = result;
        setUserSession(res.data.token, res.data.user);
        if (res?.data?.user?.at(0)?.type === "BARBER_B") {
          sessionStorage.clear()
          setIsModalOpen(true)
        } else {
          notification.success({ message })
          navigate("/boards");
        }
      })
      .catch((error) => {
        const errMsg = error.response.data.message;
        toast.error(errMsg);
        setLoading(false);
      });
  };

  return (
    <>
      {/* <ToastContainer /> */}
      {/* ====================== Start::  AskAdminPermissionModel =============================== */}
      <Fade right>
        <div
          className={`min-h-full w-screen z-50 bg-opacity-30 backdrop-blur-sm fixed flex items-center justify-center px-4 ${askAdminPermissionModel === true ? "block" : "hidden"
            }`}
        >
          <div className="bg-white w-1/2 shadow-2xl rounded-lg p-4">
            <div className="card-title w-full flex  flex-wrap justify-center items-center  ">
              <h1 className="font-bold text-sm text-center w-11/12">
                Request admin permission
              </h1>
              <hr className=" bg-primary border-b w-full -mb-6" />
            </div>
            <div className="card-body">
              <form className="-mb-10 px-8">
                <div>
                  <h2 className="text-base m-4">
                    If you want to join our Team, Call Admin or Text him{" "}
                  </h2>
                </div>
                <div className="w-full flex justify-between">
                  <button
                    className="btn btn-danger light shadow-none"
                    onClick={(e) => adminPermission(e.preventDefault())}
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Fade>
      {/* =========================== End::  AskAdminPermissionModel =============================== */}

      <div className="min-h-screen bg-black flex">
        <div className="m-auto grid grid-cols-1 lg:grid-cols-2 gap-4 p-2 md:p-4">
          {/* Logo Section */}
          <div className="flex items-center justify-center lg:justify-start">
            <div className="text-center md:ml-14">
              <img src={Logo} alt="Logo" className="mx-auto lg:mx-0 mb-4 w-30 h-10" />
              <h2 className="text-4xl text-white font-bold">Welcome to,</h2>
              <h2 className="text-4xl text-white font-bold">{`14 Barber ${isAttendancePage ? "Attendance " : "Dashboard"}`}</h2>

              {/* <h1 className="text-5xl text-white font-bold"></h1> */}
            </div>
          </div>

          {/* Form Section */}
          <div className="bg-white p-8 rounded-xl shadow-xl">
            {!isAttendancePage && <h2 className="text-2xl font-bold mb-2 text-center text-[#111]">Sign In</h2>}
            <p className="text-sm mb-8 text-center text-[#111]">Enter your Credentials</p>
            <form className="space-y-6" onSubmit={handleLogin}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="w-full px-4 py-2 border rounded-md text-[#111]"
                required
              />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className="w-full px-4 py-2 border rounded-md text-[#111]"
                required
              />
              <div className="flex justify-between items-center text-sm">
                <label className="flex items-center">
                  <input type="checkbox" className="form-checkbox" />
                  <span className="ml-2 text-[#111]">Remember me</span>
                </label>
                <Link to="/forget" className="text-[#4033f3] hover:underline ">
                  <label>
                    Forgot your password?
                  </label>
                </Link>

              </div>
              <button
                type="submit"
                disabled={loading}
                className="w-full bg-black text-white py-2 rounded-md hover:bg-back transition duration-300"
              >
                {loading ? 'Signing In...' : isAttendancePage ? "Submit" : "Sign In"}
              </button>
            </form>
            {!isAttendancePage && <p className="text-center mt-6 text-sm text-[#3c3c3c]">
              Not a Member yet? <button
                className="text-black hover:underline"
                onClick={adminPermission}
              >
                <strong>Sign Up</strong>                  </button>
            </p>}
          </div>
        </div>
        <ToastContainer />
      </div>
      <AttandancePopUp isModalOpen={isModalOpen} handleCancel={handleCancel} />
    </>
  );
}
