import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "settings",
  initialState: {
    isFetching: false,
    topServices: null,
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setTopServices(state, action) {
      state.topServices = action.payload;
    },
  },
});

export const myDashboardActions = mySlice.actions;

export default mySlice.reducer;
