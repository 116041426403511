import { createSlice } from "@reduxjs/toolkit";

const mySlice = createSlice({
  name: "settings",
  initialState: {
    isFetching: false,
    isUpdating: false,
    all: null,
    new: null,
    allSubscrition: null,
    newSubscription: null,
    errorMassage: null,
    allSubManagement: null,
    allAttandance: null,
    allUser: null,
    selectedUser: [],
  },
  reducers: {
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setIsUpdating(state, action) {
      state.isUpdating = action.payload;
    },
    setAll(state, action) {
      state.all = action.payload;
    },
    setNew(state, action) {
      state.new = action.payload;
    },
    setAllSubscrition(state, action) {
      state.allSubscrition = action.payload;
    },
    setNewSubscription(state, action) {
      state.newSubscription = action.payload;
    },
    setErrorMassage(state, action) {
      state.errorMassage = action.payload;
    },
    setAllSubManagement(state, action) {
      state.allSubManagement = action.payload;
    },
    setAllAttandance(state, action) {
      state.allAttandance = action.payload;
    },
    setAllUser(state, action) {
      state.allUser = action.payload;
    },

    setSelectedUser(state, action) {
      const { teamMember } = action.payload;

      if (!teamMember?._id) {
        return;
      }

      const exists = state.selectedUser.some((user) => user._id === teamMember._id);

      if (!exists) {
        state.selectedUser.push(teamMember);
      }
    }
  },
});

export const mySettingActions = mySlice.actions;

export default mySlice.reducer;
