import { notification } from "antd";
import { mySettingActions } from ".";
import {
  assignSubscriptionToClientService,
  createDiscountService,
  createSubscriptionService,
  deleteDiscountService,
  deleteSubscriptionService,
  getAllDiscountsService,
  getAllSubscriptionsService,
  getAllUsersService,
  getAttandanceService,
  getOneUsersService,
  getSubManagementService,
  updateDiscountService,
  updateSubscriptionPriceService,
  updateSubscriptionService,
} from "./services";

export const createDiscountAction = (data, query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsFetching(true));
      dispatch(mySettingActions.setErrorMassage(null));
      const res = await createDiscountService(data);
      if (res?.status === 201) {
        const resAll = await getAllDiscountsService(query);
        dispatch(mySettingActions.setAll(resAll));
        dispatch(mySettingActions.setNew(res));
        dispatch(mySettingActions.setIsFetching(false));
        return true

      } else if (res?.status === 409) {
        dispatch(mySettingActions.setErrorMassage("Discount Name Already Exist :)"));
        dispatch(mySettingActions.setIsFetching(false));
      }
      if (res?.response?.status === 500) {
        notification.success({
          message: "Something Went Wrong, Please Try Again",
        });
      }
      dispatch(mySettingActions.setIsFetching(false));
      return false
    } catch (err) {
      console.error(err);
    }
  };
};

export const getAllDiscountsAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsFetching(true));
      const res = await getAllDiscountsService(query);
      if (res?.status === 200) {
        dispatch(mySettingActions.setAll(res));
        dispatch(mySettingActions.setIsFetching(false));
      }
      dispatch(mySettingActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateDiscountActions = (itemId, data, query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsUpdating(true));
      const res = await updateDiscountService(itemId, data);
      if (res?.status === 200) {
        const resAll = await getAllDiscountsService(query);
        dispatch(mySettingActions.setAll(resAll));
        dispatch(mySettingActions.setIsUpdating(false));
        return true
      }
      dispatch(mySettingActions.setIsUpdating(false));
      return false
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteDiscountActions = (itemId, query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsUpdating(true));
      const res = await deleteDiscountService(itemId);
      if (res?.status === 200) {
        const resAll = await getAllDiscountsService(query);
        dispatch(mySettingActions.setAll(resAll));
        dispatch(mySettingActions.setIsUpdating(false));
        return true
      }
      dispatch(mySettingActions.setIsUpdating(false));
      return false
    } catch (err) {
      console.log(err);
    }
  };
};

// Subscription


export const createSubscriptionAction = (data, query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsFetching(true));
      dispatch(mySettingActions.setErrorMassage(null));
      const res = await createSubscriptionService(data);
      if (res?.status === 201) {
        const resAll = await getAllSubscriptionsService(query);
        dispatch(mySettingActions.setAllSubscrition(resAll));
        dispatch(mySettingActions.setNewSubscription(res));
        dispatch(mySettingActions.setIsFetching(false));
        return true

      } else if (res?.status === 409) {
        dispatch(mySettingActions.setErrorMassage("Subscription Already Exist :)"));
        dispatch(mySettingActions.setIsFetching(false));
      }
      if (res?.response?.status === 500) {
        notification.success({
          message: "Something Went Wrong, Please Try Again",
        });
      }
      dispatch(mySettingActions.setIsFetching(false));
      return false
    } catch (err) {
      console.error(err);
    }
  };
};

export const getAllSubscriptionsAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsFetching(true));
      const res = await getAllSubscriptionsService(query);
      if (res?.status === 200) {
        dispatch(mySettingActions.setAllSubscrition(res));
        dispatch(mySettingActions.setIsFetching(false));
      }
      dispatch(mySettingActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateSubscriptionActions = (itemId, data, query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsUpdating(true));
      const res = await updateSubscriptionService(itemId, data);
      if (res?.status === 200) {
        const resAll = await getAllSubscriptionsService(query);
        dispatch(mySettingActions.setAllSubscrition(resAll));
        dispatch(mySettingActions.setIsUpdating(false));
        return true
      }
      dispatch(mySettingActions.setIsUpdating(false));
      return false
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateSubscriptionPriceActions = (itemId, data, query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsUpdating(true));
      const res = await updateSubscriptionPriceService(itemId, data);
      if (res?.status === 200) {
        const resAll = await getAllSubscriptionsService(query);
        dispatch(mySettingActions.setAllSubscrition(resAll));
        dispatch(mySettingActions.setIsUpdating(false));
        return true
      }
      dispatch(mySettingActions.setIsUpdating(false));
      return false
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteSubscriptionActions = (itemId, query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsUpdating(true));
      const res = await deleteSubscriptionService(itemId);
      if (res?.status === 200) {
        const resAll = await getAllSubscriptionsService(query);
        dispatch(mySettingActions.setAllSubscrition(resAll));
        dispatch(mySettingActions.setIsUpdating(false));
        return true
      }
      dispatch(mySettingActions.setIsUpdating(false));
      return false
    } catch (err) {
      console.log(err);
    }
  };
};

// Subscription Management


export const assignSubscriptionToClientAction = (data, query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsFetching(true));
      const res = await assignSubscriptionToClientService(data);
      if (res?.status === 201) {
        dispatch(mySettingActions.setIsFetching(false));
        return true
      }
      if (res?.response?.status === 500) {
        notification.success({
          message: "Something Went Wrong, Please Try Again",
        });
      }
      dispatch(mySettingActions.setIsFetching(false));
      return false
    } catch (err) {
      console.error(err);
    }
  };
};

export const getAllSubManagementAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsFetching(true));
      const res = await getSubManagementService(query);
      if (res?.status === 200) {
        dispatch(mySettingActions.setAllSubManagement(res));
        dispatch(mySettingActions.setIsFetching(false));
      }
      dispatch(mySettingActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//Attandance
export const getAllAttandanceAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsFetching(true));
      const res = await getAttandanceService(query);
      if (res?.status === 200) {
        dispatch(mySettingActions.setAllAttandance(res?.data));
        dispatch(mySettingActions.setIsFetching(false));
      }
      dispatch(mySettingActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

//Team Members
export const getAllTeamMembersAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsFetching(true));
      const res = await getAllUsersService(query);
      if (res?.status === "1") {
        dispatch(mySettingActions.setAllUser(res?.data));
        dispatch(mySettingActions.setIsFetching(false));
      }
      dispatch(mySettingActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getOneTeamMembersAction = (itemId, query) => {
  return async (dispatch) => {
    try {
      dispatch(mySettingActions.setIsFetching(true));
      const res = await getOneUsersService(itemId, query);
      if (res?.status === "1") {
        dispatch(mySettingActions.setSelectedUser({ teamMember: res?.data, }));
        dispatch(mySettingActions.setIsFetching(false));
      }
      dispatch(mySettingActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};


