import React from "react";
import { getUser } from "../utils/common";
import { useSelector } from "react-redux";
import { convertToCairoTime } from "../../utils/helper";

const AttandanceTable = () => {
  const { setting } = useSelector((state) => state);

  const user = getUser();
  const findRole = user[0]?.roles[0]?.permissions;
  const permissions = [];
  findRole?.map((item) => permissions.push(item.permissions));

  return (
    <>
      <div className="table-responsive">
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table id="table-to-xls" className="display table-hover w-full">
                <thead>
                  <tr className="border-b">
                    <th className="py-6">Date</th>
                    <th className="py-6">Time</th>
                    <th className="py-6">Names</th>
                    <th className="py-6">Location</th>
                  </tr>
                </thead>
                <tbody>
                  {setting?.allAttandance?.map((item) => {
                    return (
                      <tr key={item._id} className="border-b">
                        <td className="py-3">{item?.date?.slice(0, 10)}</td>
                        <td className="py-3">
                          {`${convertToCairoTime(item?.date)}`}
                        </td>
                        <td className="py-3 capitalize">
                          {`${item?.userId?.firstname} ${item?.userId?.lastname}`}
                        </td>
                        <td className="py-3 uppercase">
                          {item?.status?.split("_")?.join(" ")}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttandanceTable;
