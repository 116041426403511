import React from "react";
import { Modal } from "antd";
import Success from "../assets/sucessAnimation.gif";

const AttandancePopUp = ({ isModalOpen, handleCancel }) => {
  return (
    <Modal open={isModalOpen} centered footer={null} width={600}>
      <div className="relative p-4 w-full rounded-lg text-gray-900">
        <div className="px-4 md:px-5 text-center">
          <img src={Success} alt="" className="mx-auto -my-6" />

          <h3 className="mb-5 text-xl font-normal">
            Your attendance has been recorded successfully
          </h3>
          <button
            onClick={handleCancel}
            className="text-white bg-blue-600 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
          >
            Back
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AttandancePopUp;
