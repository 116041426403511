import React from "react";
import { useSelector } from "react-redux";

const TopClientForm = () => {
  const { dashboard } = useSelector((state) => state)

  return (
    <div className="col-xl-6 col-xxl-6 col-lg-12 col-md-12">
      <div id="user-activity" className="card" style={{ border: '1px solid #d1d1d1' }}>
        <div className="card-header border-0 pb-0 d-sm-flex d-block">
          <div>
            <h4 className="card-title mb-1">Top Clients</h4>
          </div>
        </div>
        <div className="card-body">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="user"
              role="tabpanel"
            >
              <table className="table xtable-responsive-md">
                <thead>
                  <tr >
                    <th className="text-left">
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>AMOUNT (RWF)</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dashboard?.topServices?.topCustomer?.map((item, index) => (
                    <tr key={index}>
                      <td className="text-left">{item.clientName}</td>
                      <td>{item?.totalAmountSpent?.toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopClientForm;
