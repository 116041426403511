import { notification } from "antd";
import { myClienttActions } from ".";
import { createClientService, getClientService, updateClientService } from "./services";

export const updateClientActions = (itemId, data) => {
  return async (dispatch) => {
    try {
      dispatch(myClienttActions.setIsFetching(true));
      const res = await updateClientService(itemId, data);
      if (res.response?.status === 400) {
        notification.error({ message: res.response?.data?.errors[0] });
        dispatch(myClienttActions.setIsFetching(false));
      }
      dispatch(myClienttActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAllClientClientAction = (query) => {
  return async (dispatch) => {
    try {
      dispatch(myClienttActions.setIsFetching(true));
      const res = await getClientService(query);
      if (res?.status === "1") {
        dispatch(myClienttActions.setAll(res));
        dispatch(myClienttActions.setIsFetching(false));
      }
      dispatch(myClienttActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createNewClientActions = (data) => {
  return async (dispatch) => {
    try {
      dispatch(myClienttActions.setError(null));
      dispatch(myClienttActions.setIsFetching(true));
      const res = await createClientService(data);
      console.log("🚀 ~ return ~ res:", res?.status)
      if (res?.status === "1") {
        dispatch(myClienttActions.setNew(res.cart));
        dispatch(myClienttActions.setIsFetching(false));
        return true
      } else if (res?.status === 409) {
        dispatch(myClienttActions.setIsFetching(false));
        dispatch(myClienttActions.setError(res?.response?.data?.message));
      }
      dispatch(myClienttActions.setIsFetching(false));
      return false
    } catch (err) {
      console.log(err);
    }
  };
};


