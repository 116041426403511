import React from "react";
import { Fade } from "react-reveal";
import { axiosRequest } from "../../../../api";
import { useMediaQuery } from "@mui/material";

const Cancel_appointment_URL = "appointment/cancel";

const AppointmentDetailsFrom = ({
  showEventModel,
  setShowEventModel,
  barber,
  title,
  serviceName,
  start,
  end,
  itemId,
}) => {
  const isTablet = useMediaQuery("(max-width: 960px)");

  const showDataModel = () => {
    let newState = !showEventModel;
    setShowEventModel(newState);
  };

  const cancelAppointment = (id, e) => {
    e.preventDefault();

    axiosRequest
      .put(Cancel_appointment_URL + "/" + id)
      .then((response) => {
        const result = response.data.data;
        setShowEventModel(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Fade right>
      <div
        className={`min-h-full w-screen z-50 bg-opacity-30 backdrop-blur-sm fixed flex items-center justify-center px-4 ${
          showEventModel === true ? "block" : "hidden"
        }`}
      >
        <div
          className={`bg-white ${
            isTablet ? "w-full" : "w-[35%]"
          } shadow-2xl rounded-lg p-4`}
        >
          <div className="card-title w-full flex flex-wrap justify-center items-center text-black">
            <h1 className="font-bold text-sm text-center -mb-2 w-11/12">
              Appointment Details
            </h1>
            <hr className="bg-primary border-b w-full -mb-6" />
          </div>
          <div className="card-body text-black">
            <form className="-mb-10">
              <div className="flex flex-col gap-2">
                <div className="flex justify-between">
                  <label className="text-sm text-gray-400">Barber Name:</label>
                  <p className="text-sm">{barber}</p>
                </div>
                <div className="flex justify-between">
                  <label className="text-sm text-gray-400">Client Name:</label>
                  <p className="text-sm">{title}</p>
                </div>
                <div className="flex justify-between">
                  <label className="text-sm text-gray-400">Service:</label>
                  <p className="text-sm">{serviceName}</p>
                </div>
                <div className="flex justify-between">
                  <label className="text-sm text-gray-400">From:</label>
                  <p className="text-sm">{start.toString().split(" ")[4]}</p>
                </div>
                <div className="flex justify-between">
                  <label className="text-sm text-gray-400">To:</label>
                  <p className="text-sm">{end.toString().split(" ")[4]}</p>
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <button
                  className="px-3 py-1 text-black bg-white border-2 border-black rounded"
                  onClick={(e) => showDataModel(e.preventDefault())}
                >
                  Back
                </button>
                <button
                  className="ml-4 px-3 py-1 text-white bg-black rounded "
                  onClick={(e) => cancelAppointment(itemId, e)}
                >
                  Remove
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default AppointmentDetailsFrom;
