import { myLayoutActions } from ".";

export const handleSeletSettingTab = (tab) => {
  return async (dispatch) => {
    dispatch(myLayoutActions.setCurrentSettingTab(tab));
  };
};

export const handleSeletAppointmentDate = (date) => {
  return async (dispatch) => {
    dispatch(myLayoutActions.setAppointmentDate(date));
    return true
  };
};