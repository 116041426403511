import { SERVER_URL } from "../../utils/constants";
import HttpRequest from "../../utils/HttpRequest";

export const updateClientService = async (itemId, data) => {
  return await HttpRequest.update(
    `${SERVER_URL}/client/${itemId}`,
    data
  );
};

export const getClientService = async () => {
  return await HttpRequest.get(
    `${SERVER_URL}/client`,
  );
};

export const createClientService = async (data) => {
  return await HttpRequest.post(`${SERVER_URL}/client`, data);
};
