import React, { useEffect, useState } from "react";
import { Button, Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewClientActions,
  getAllClientClientAction,
} from "../../store/client/actions";
import { axiosRequest } from "../../api";

const Gender_URL = "gender";

const CreateClientForm = ({ onCancel }) => {
  const { client } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [genderData, setGenderData] = useState([]);

  const onFinish = async (values) => {
    const payload = {
      password: "kigali123",
      email: "client@gmail.com",
      ...values,
    };
    const res = await createNewClientActions(payload)(dispatch);
    if (res) {
      await getAllClientClientAction()(dispatch);
      await onCancel();
      form.resetFields();
    }
  };

  useEffect(() => {
    axiosRequest
      .get(Gender_URL)
      .then((response) => {
        const result = response.data.data;
        setGenderData(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);
  return (
    <Form
      name="basic"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: "100%" }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Form.Item
        name="firstname"
        label={<h1 className="text-[#030229B2] text-base mt-2">First Name</h1>}
        rules={[
          {
            required: true,
            message: "First Name is required!",
            whitespace: true,
          },
        ]}
      >
        <Input className="h-12" />
      </Form.Item>
      <Form.Item
        name="lastname"
        label={<h1 className="text-[#030229B2] text-base mt-2">Last Name</h1>}
        rules={[
          {
            required: true,
            message: "Last Name is required!",
            whitespace: true,
          },
        ]}
      >
        <Input className="h-12" />
      </Form.Item>
      <Form.Item
        name="telephone"
        label={<h1 className="text-[#030229B2] text-base mt-2">Phone</h1>}
        rules={[
          {
            required: true,
            message: "Telephone is required!",
            whitespace: true,
          },
        ]}
      >
        <Input className="h-12" />
      </Form.Item>
      <Form.Item
        name="gender"
        label={<h1 className="text-[#030229B2] text-base mt-2">Gender</h1>}
        rules={[
          {
            required: true,
            message: "Gendar is required!",
            whitespace: true,
          },
        ]}
      >
        <Select className="h-12">
          {genderData?.map((el) => (
            <Select.Option key={el?._id} value={el?._id} className="capitalize">
              {el.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="email"
        label={<h1 className="text-[#030229B2] text-base mt-2">Email</h1>}
      >
        <Input className="h-12" />
      </Form.Item>
      {client?.error !== null && (
        <span className="flex items-center mx-auto justify-center pb-2 text-red-500 capitalize">
          {client?.error}
        </span>
      )}
      <Form.Item className="w-full" wrapperCol={{ offset: 8 }}>
        <Button
          style={{
            backgroundColor: "#000",
          }}
          type="primary"
          htmlType="submit"
          className="w-1/2 h-12"
          loading={client?.isFetching}
          disable={client?.isFetching}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateClientForm;
