/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import "react-datepicker/dist/react-datepicker.css";
import Dashboard from "../../Dashboard";
import { axiosRequest, refreshPage } from "../../../api/index";
import { toast, ToastContainer } from "react-toastify";
import { getUser } from "../../utils/common";
import { useMediaQuery } from "@mui/material";
import AddNewAppointmentForm from "./forms/AddNewAppointmentForm";
import { useDispatch } from "react-redux";
import { getAllTeamMembersAction } from "../../../store/settings/actions";
import AppointmentDetailsFrom from "./forms/AppointmentDetailsFrom";
import { handleSeletAppointmentDate } from "../../../store/layout/actions";
import { Tabs } from "antd";
import AppointmentTables from "./AppointmentTables";

const Client_URL = "client";
const Appointment_URL = "appointment?status=pending";

const Appointment = () => {
  const [addEventModel, setAddEventModel] = useState(false);
  const [showEventModel, setShowEventModel] = useState(false);
  const [Data, setData] = useState([]);
  const [client, setClient] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apByMemberIdData, setApByMemberIdData] = useState([]);
  const dispatch = useDispatch();

  const isTablet = useMediaQuery("(max-width: 960px)");

  const user = getUser();
  const findRole = user[0]?.roles[0]?.permissions;
  const permissions = [];
  findRole?.map((item) => permissions.push(item.permissions));

  const showData = [];
  const data = Data || [];
  data.forEach((item) => {
    showData.push({
      title: item?.telephone?.firstname,
      start: item.start,
      end: item.end,
      app_id: item._id,
      barber: item?.teammember?.lastname,
      serviceName:
        item?.services?.length > 0
          ? item?.services?.map((el) => el?.servicename)?.join(", ")
          : item?.service_id?.servicename,
      backgroundColor: "#e3b04b",
    });
  });

  const startTime = [];
  const value = apByMemberIdData || [];
  value?.forEach((item) => {
    startTime.push({
      time: item?.unvaliableTimeStart?.split(" ")[1],
      end: item?.end?.split(" ")[1],
    });
  });

  const [newEvent, setNewEvent] = useState({
    service_id: "",
    teammember: "",
    telephone: "",
    appointment_date: "",
    start_time: "",
  });

  const serviceId = newEvent.service_id;
  const teamMemberId = newEvent.teammember;

  const [title, setTitle] = useState("");
  const [barber, setBarber] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [start, setStart] = useState(Date());
  const [end, setEnd] = useState(Date());
  const [itemId, setItemId] = useState("");

  const handleEventClick = (clickInfo) => {
    setTitle(clickInfo.event.title);
    setStart(clickInfo.event.start);
    setEnd(clickInfo.event.end);
    setBarber(clickInfo.event.extendedProps.barber);
    setServiceName(clickInfo.event.extendedProps.serviceName);
    setItemId(clickInfo.event.extendedProps.app_id);
    setShowEventModel(true);
  };

  const removeModel = async (e) => {
    e?.preventDefault();
    const newState = !addEventModel;
    setAddEventModel(newState);
  };

  const getClient = () => {
    axiosRequest
      .get(Client_URL)
      .then((response) => {
        const result = response.data.data;
        setClient(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getApByMemberId = async () => {
    if (teamMemberId) {
      const url = `appointment/team/${teamMemberId}`;
      await axiosRequest
        .get(url)
        .then((response) => {
          const result = response.data.data;
          setApByMemberIdData(result);
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  const getAppointment = () => {
    axiosRequest
      .get(Appointment_URL)
      .then((response) => {
        const result = response.data.data;
        setData(result);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    const url = "appointment";
    setLoading(true);
    await axiosRequest
      .post(url, {
        ...newEvent,
        isNotify: true,
        notification: {
          action: "Appointment",
          role: ["ADMIN_A", "MANAGER_M", "CASHIER_C"],
          message: `Appointment`,
          title: `Appointment`,
        },
      })
      .then((res) => {
        setNewEvent({
          service_id: "",
          teammember: "",
          telephone: "",
          appointment_date: "",
          start_time: "",
        });
        setLoading(false);
        const result = res.data;
        const { message } = result;
        toast.success(message);
        setTimeout(() => {
          refreshPage();
          getAppointment();
        }, 1000);
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_RESPONSE") {
          toast.error(error.response.data.message);
        } else {
          toast.info(error.message);
          setTimeout(() => {
            setLoading(false);
            refreshPage();
          }, 2000);
        }
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      axiosRequest
        .get(Appointment_URL)
        .then((response) => {
          const result = response.data.data;
          setData((prevData) => {
            return JSON.stringify(prevData) !== JSON.stringify(result)
              ? result
              : prevData;
          });
        })
        .catch((error) => console.log(error.message));
    }, 3000);
    getClient();
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getApByMemberId();
  }, [serviceId, teamMemberId]);

  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  useEffect(() => {
    getAllTeamMembersAction(`?excludeTypes=ADMIN_A,CASHIER_C`)(dispatch);
  }, [dispatch]);

  const handleDateSelect = async (selectInfo) => {
    const res = await handleSeletAppointmentDate(selectInfo.startStr)(dispatch);
    if (res) {
      const newState = !addEventModel;
      setAddEventModel(newState);
    }
  };

  const appointmentTabs = [
    {
      label: "Calendar",
      key: "1",
      children: (
        <FullCalendar
          plugins={[daygridPlugin, interactionPlugin, listPlugin]}
          headerToolbar={{
            left: "today prev,next",
            right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek",
          }}
          editable={true}
          selectable={true}
          selectMirror={true}
          events={showData}
          eventContent={(info) => <EventItem info={info} />}
          initialView="dayGridMonth"
          eventClick={handleEventClick}
          eventAdd={(e) => handlerSubmit(e)}
          contentHeight={"auto"}
          select={handleDateSelect}
        />
      ),
    },
    {
      label: "List",
      key: "2",
      children: <AppointmentTables />,
    },
  ];

  return (
    <>
      <ToastContainer />
      <Dashboard>
        {/* =========================== Start::  ScheduleAppointmentModel =========================== */}
        <AddNewAppointmentForm
          addEventModel={addEventModel}
          removeModel={removeModel}
          client={client}
          loading={loading}
        />
        {/* =========================== End::    ScheduleAppointmentModel =========================== */}

        {/* ====================== Start::  ShowEventModel =============================== */}
        <AppointmentDetailsFrom
          showEventModel={showEventModel}
          setShowEventModel={setShowEventModel}
          barber={barber}
          title={title}
          serviceName={serviceName}
          start={start}
          end={end}
          itemId={itemId}
        />
        {/* =========================== End::  ShowEventModel =============================== */}

        <div
          className={`relative pt-10  md:px-40 md:pl-80 pb-20  text-black mt-[4rem] ${
            !isTablet && "ml-52 w-4/5 px-4 min-h-screen"
          }`}
        >
          {permissions.indexOf("add-appointment") !== -1 && (
            <button
              className="absolute right-6 btn btn-sm btn-primary light d-flex align-items-center svg-btn shadow-none"
              onClick={removeModel}
            >
              <span className="fs-16 ">make Appointment</span>
            </button>
          )}
          <div className="mt-14">
            <Tabs defaultActiveKey="1" items={appointmentTabs} centered />
          </div>
        </div>
      </Dashboard>
    </>
  );
};

export default Appointment;

const EventItem = ({ info }) => {
  const { event } = info;
  return (
    <div
      style={{
        backgroundColor: event.backgroundColor,
        width: "100%",
        height: "120px",
        marginBottom: "10px",
        borderRadius: "8px",
        padding: "10px",
        color: "white",
      }}
    >
      <p style={{ overflow: "hidden" }}>
        Client: <b>{event.title}</b>
      </p>
      <p style={{ overflow: "hidden" }}>
        {event.extendedProps.serviceName} with{" "}
      </p>
      <p style={{ overflow: "hidden" }}>
        <b>{event.extendedProps.barber}</b>
      </p>
      <p style={{ overflow: "hidden" }}>
        {" "}
        at <b>{info.timeText}</b>
      </p>
    </div>
  );
};
