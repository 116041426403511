import React, { useEffect, useState } from "react";
import Dashboard from "../../Dashboard";
import { useMediaQuery } from "@mui/material";
import { getUser } from "../../utils/common";
import { useDispatch } from "react-redux";
import AttandanceTable from "../../tables/AttandanceTable";
import { getAllAttandanceAction } from "../../../store/settings/actions";
import { DatePicker, Input } from "antd";
import dayjs from "dayjs";
const { Search } = Input;

const AttandanceManagement = () => {
  const dispatch = useDispatch();
  const currentDate = new Date().toISOString().slice(0, 10);

  const isTablet = useMediaQuery("(max-width: 960px)");
  const user = getUser();
  const findRole = user[0]?.roles[0]?.permissions;
  const permissions = [];
  findRole?.map((item) => permissions.push(item.permissions));

  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (["", null, undefined]?.includes(search))
      getAllAttandanceAction(`?date=${selectedDate}`)(dispatch);
  }, [dispatch, selectedDate, search]);

  const handleSerch = () => {
    getAllAttandanceAction(`?search=${search}`)(dispatch);
  };

  const onChangeDate = (_date, dateString) => {
    setSelectedDate(dateString);
  };

  return (
    <Dashboard>
      <div className={`content-body mt-[3rem] ${!isTablet && "ml-52"}`}>
        <div className="container-fluid">
          <div
            className={`relative form-head mb-3 flex items-center justify-between`}
          >
            <div className="mr-auto d-lg-block">
              <h3 className="text-black font-w500 mb-6">Attandence</h3>
            </div>
          </div>

          <div className="mb-4 flex items-center justify-between">
            <DatePicker
              defaultValue={dayjs()}
              allowClear={false}
              className="h-10 w-44"
              onChange={onChangeDate}
            />
            <Search
              placeholder="Search by first-name or last-name"
              onChange={(e) => setSearch(e.target.value)}
              enterButton
              size="large"
              className="w-80"
              onSearch={handleSerch}
            />
          </div>

          <div className="row">
            <div className="col-12">
              <AttandanceTable />
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  );
};

export default AttandanceManagement;
