import React, { useEffect, useState } from "react";
import LoadingButton from "../../../LoadingButton";
import { Fade } from "react-reveal";
import { Form, Select } from "antd";
import { axiosRequest } from "../../../../api";
import { toast } from "react-toastify";

const ServicesForm = ({
  assignNewServiceModel,
  isTablet,
  assignServiceModel,
  getServiceData,
  userId,
  onCloseUserModal,
  getTeamMember,
  currentServices,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    const url = `team/${userId}`;
    setLoading(true);
    axiosRequest
      .put(url, values)
      .then((response) => {
        setLoading(false);
        const result = response.data.message;
        toast.success(result);
        onCloseUserModal();
        form.resetFields();
        setTimeout(() => {
          getTeamMember();
        }, 500);
      })
      .catch((error) => {
        toast.info(error.response.data.message);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      });
  };

  useEffect(() => {
    if (Array.isArray(currentServices) && currentServices.length > 0) {
      const defaultServiceIds = currentServices?.map((service) => service?._id);

      form.setFieldsValue({
        services_id: defaultServiceIds,
      });
    } else {
      form.resetFields();
    }
  }, [currentServices, form]);

  return (
    <Fade top>
      <div
        className={`h-screen w-full bg-opacity-30 backdrop-blur-sm lg:mt-0 fixed flex items-center justify-center z-50 ${
          assignNewServiceModel === true ? "block" : "hidden"
        }`}
      >
        <div className="bg-white w-screen shadow-2xl rounded-lg p-4 pb-8 -ml-8 -mt-8">
          <div className="w-full">
            <div className="modal-content">
              <div className="modal-header justify-content-center">
                {!isTablet && (
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={(e) => assignServiceModel(e.preventDefault())}
                  >
                    <span>&times;</span>
                  </button>
                )}
                <h2 className="modal-title text-black font-w600 ">
                  Assign Servicers
                </h2>
              </div>
              <Form
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
              >
                <div className="modal-body mt-[4rem] mb-4">
                  <div className="row justify-content-center  align-items-center">
                    <div className="col-xl-6 col-lg-12">
                      <div className="basic-form">
                        <div className=" custom-card">
                          <div className="card-header">
                            <h4 className="card-title">Select Service</h4>
                          </div>
                          <div className="card-body">
                            <div className="form-row ">
                              <div className="form-group col-md-12">
                                <Form.Item
                                  label="Service"
                                  name="services_id"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your username!",
                                    },
                                  ]}
                                >
                                  <Select
                                    //   onChange={setHandle}
                                    mode="multiple"
                                    size="large"
                                  >
                                    {Array.isArray(getServiceData) &&
                                      getServiceData?.map((el) => (
                                        <Select.Option
                                          key={el?._id}
                                          values={el?._id}
                                        >
                                          {el?.servicename}
                                        </Select.Option>
                                      ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div>
                    <button
                      type="button"
                      className="bg-[#e3b04b] light mr-3 p-2.5 text-white font-semibold rounded"
                      data-dismiss="modal"
                      onClick={(e) => assignServiceModel(e.preventDefault())}
                    >
                      Close
                    </button>
                    {loading ? (
                      <LoadingButton />
                    ) : (
                      <button
                        type="submit"
                        htmlType="submit"
                        className="bg-[#1b1a17] hover:bg-[#cf7500] p-2.5 text-white font-semibold rounded"
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default ServicesForm;
