import { Drawer } from 'antd'
import React from 'react'

const DrowerModel = ({ title, open, handleClose, components }) => {
    return (
        <Drawer
            title={title}
            placement="right"
            size={"large"}
            onClose={handleClose}
            open={open}

        >
            {components}
        </Drawer>
    )
}

export default DrowerModel
