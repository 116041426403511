import React, { useEffect, useState } from "react";
import { Button, DatePicker, Divider, Form, Input, Modal, Select } from "antd";
import { IoMdAdd } from "react-icons/io";
import { CgRemove } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { getOneTeamMembersAction } from "../../../../store/settings/actions";
import { Fade } from "react-reveal";
import { useMediaQuery } from "@mui/material";
import { handleSeletAppointmentDate } from "../../../../store/layout/actions";
import LoadingButton from "../../../LoadingButton";
import { createAppointmentActions } from "../../../../store/appointment/actions";
import { mySettingActions } from "../../../../store/settings";
import dayjs from "dayjs";
import CreateClientForm from "../../../form/createClientForm";
import { getAllClientClientAction } from "../../../../store/client/actions";

const { TextArea } = Input;

const AddNewAppointmentForm = ({ addEventModel, removeModel }) => {
  const { setting, layout, appointment, client } = useSelector(
    (state) => state
  );
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isTablet = useMediaQuery("(max-width: 960px)");
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);

  const showClientModal = () => {
    setIsClientModalOpen(true);
  };

  const handleCancel = () => {
    setIsClientModalOpen(false);
  };

  const handleCloseModal = () => {
    form.resetFields();
    handleSeletAppointmentDate(null)(dispatch);
    dispatch(mySettingActions.setSelectedUser([]));
    removeModel();
  };

  const onFinish = async (values) => {
    const payload = {
      ...values,
      appointment_date: layout.appointmentDate,
      isNotify: true,
      notification: {
        action: "Appointment",
        role: ["ADMIN_A", "MANAGER_M", "CASHIER_C"],
        message: `Appointment`,
        title: `Appointment`,
      },
    };

    const res = await createAppointmentActions(payload)(dispatch);
    if (res) {
      handleCloseModal();
    }
  };

  const onChange = (date, dateString) => {
    handleSeletAppointmentDate(dateString)(dispatch);
    const selectedClient = form.getFieldValue("telephone");

    form.resetFields();
    form.setFieldsValue({
      telephone: selectedClient,
      appointmentDate: date,
    });
  };

  const handleSelectBarber = (barberSelected) => {
    getOneTeamMembersAction(
      barberSelected,
      `?appointment_date=${layout.appointmentDate}`
    )(dispatch);
  };

  const handleServicesDuration = (selectedValues, name) => {
    const teamMemberId = form?.getFieldValue(["store", name, "teammember"]);
    const selectedTeamMember = setting?.selectedUser?.find(
      (user) => user._id === teamMemberId
    );

    if (!selectedTeamMember) {
      return;
    }

    const selectedDurations = selectedTeamMember?.services_id
      ?.filter((service) => selectedValues.includes(service._id))
      .reduce((acc, service) => {
        acc[service._id] = parseInt(service.duration, 10) || 0;
        return acc;
      }, {});

    const totalDuration = Object.values(selectedDurations).reduce(
      (sum, dur) => sum + dur,
      0
    );

    form.setFieldsValue({
      store: form?.getFieldValue("store")?.map((item, index) =>
        index === name
          ? {
              ...item,
              services: selectedValues,
              durations: totalDuration,
            }
          : item
      ),
    });
  };

  const getUpdatedFields = (form, selectedUsers) => {
    return form?.getFieldValue("store")?.map((field) => {
      const teamMemberId = field?.teammember;
      const selectedTeamMember = selectedUsers?.find(
        (user) => user._id === teamMemberId
      );

      return {
        ...field,
        services: selectedTeamMember?.services_id || [],
      };
    });
  };

  const handleClickUpdate = () => {
    getUpdatedFields(form, setting?.selectedUser);
  };

  useEffect(() => {
    if (layout.appointmentDate) {
      form.setFieldsValue({
        appointmentDate:
          layout.appointmentDate && dayjs(layout.appointmentDate).isValid()
            ? dayjs(layout.appointmentDate)
            : null,
      });
    }
  }, [form, layout.appointmentDate]);

  useEffect(() => {
    getAllClientClientAction()(dispatch);
  }, []);

  const clientOptions =
    client?.all?.data?.map((el) => ({
      value: el?._id,
      tel: el?.telephone,
      label: `${
        el?.firstname && el?.lastname
          ? `${el?.firstname} ${el?.lastname} ~ ${el?.telephone}`
          : el?.firstname || el?.lastname
      }`,
    })) || [];

  return (
    <>
      <Fade top>
        <div
          className={`h-screen w-full bg-opacity-30 backdrop-blur-sm lg:mt-0 fixed flex items-center justify-center z-50 ${
            addEventModel === true ? "block" : "hidden"
          }`}
        >
          <div className="bg-white w-screen shadow-2xl rounded-lg p-4 pb-8 -ml-8">
            <div className="w-full">
              <div className="modal-content">
                {!isTablet && (
                  <div className="modal-header justify-content-center">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={handleCloseModal}
                    >
                      <span>&times;</span>
                    </button>
                    <h2 className="modal-title text-black font-w600">
                      Add new Appointment
                    </h2>
                  </div>
                )}
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  initialValues={{}}
                  scrollToFirstError
                  className="capitalize "
                >
                  <div className="modal-body">
                    <div
                      className={`row justify-center items-center active show overflow-y-auto ${
                        isTablet ? "h-[60vh]" : "h-[65vh]"
                      } scrollbar-hide`}
                    >
                      <div className="col-xl-6 col-lg-12">
                        <div className="basic-form">
                          <div className="custom-card">
                            <div className="card-header">
                              <h4 className="card-title">Client</h4>
                            </div>
                            <div className="card-body">
                              <div className="form-row">
                                <div className="form-group col-md-12 grid grid-cols-2 gap-x-3">
                                  <Form.Item
                                    name={"telephone"}
                                    label={
                                      <span className="text-[#0F0F47] text-sm">
                                        Client
                                      </span>
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: "Field is required!",
                                      },
                                    ]}
                                  >
                                    <Select
                                      size={"large"}
                                      showSearch
                                      placeholder="Search by telephone"
                                      optionFilterProp="tel"
                                      options={clientOptions}
                                      dropdownRender={(menu) => (
                                        <div>
                                          {menu}
                                          <hr />
                                          <Button
                                            disabled={client?.isFetching}
                                            style={{
                                              backgroundColor: "#f0a907",
                                              color: "white",
                                              textAlign: "center",
                                              width: "100%",
                                            }}
                                            onClick={showClientModal}
                                            className="bg-[#f0a907] h-10 text-base text-white text-center w-full rounded-md"
                                          >
                                            + Add New Client
                                          </Button>
                                        </div>
                                      )}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name={"appointmentDate"}
                                    label={
                                      <span className="text-[#0F0F47] text-sm">
                                        Date
                                      </span>
                                    }
                                    rules={[
                                      {
                                        required: layout.appointmentDate
                                          ? false
                                          : true,
                                        message: "Field is required!",
                                      },
                                    ]}
                                  >
                                    <DatePicker
                                      onChange={onChange}
                                      className="w-full h-10"
                                      allowClear={false}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          </div>

                          {layout.appointmentDate !== null && (
                            <div className="custom-card">
                              <div className="card-header">
                                <h4 className="card-title">Schedule</h4>
                              </div>

                              <Form.List name="store" initialValue={[{}]}>
                                {(fields, { add, remove }) => {
                                  return (
                                    <>
                                      {fields.map(
                                        ({ key, name, ...restField }) => (
                                          <div className="w-full">
                                            <div className="grid grid-cols-2 gap-x-4 w-full  mt-2 px-4">
                                              <Form.Item
                                                {...restField}
                                                name={[name, "teammember"]}
                                                label={
                                                  <span className="text-[#0F0F47] text-sm">
                                                    Team Member
                                                  </span>
                                                }
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Field is required!",
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  size={"large"}
                                                  placeholder="Please select"
                                                  onChange={(value) => {
                                                    form.setFieldsValue({
                                                      store: {
                                                        ...form.getFieldValue(
                                                          "store"
                                                        ),
                                                        [name]: {
                                                          teammember: value,
                                                          services: [],
                                                          durations: [],
                                                          start_time: undefined,
                                                        },
                                                      },
                                                    });

                                                    handleSelectBarber(value);
                                                    handleClickUpdate();
                                                  }}
                                                >
                                                  {setting?.allUser?.map(
                                                    (el) => (
                                                      <Select.Option
                                                        key={el?._id}
                                                        value={el?._id}
                                                      >
                                                        {`${el?.firstname} ${el?.lastname}`}
                                                      </Select.Option>
                                                    )
                                                  )}
                                                </Select>
                                              </Form.Item>
                                              <Form.Item
                                                {...restField}
                                                name={[name, "services"]}
                                                label={
                                                  <span className="text-[#0F0F47] text-sm">
                                                    Services
                                                  </span>
                                                }
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Field is required!",
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  mode="multiple"
                                                  size="large"
                                                  placeholder="Please select"
                                                  onChange={(value) =>
                                                    handleServicesDuration(
                                                      value,
                                                      name
                                                    )
                                                  }
                                                  disabled={
                                                    !setting?.selectedUser?.some(
                                                      (user) =>
                                                        user._id ===
                                                        form.getFieldValue([
                                                          "store",
                                                          name,
                                                          "teammember",
                                                        ])
                                                    )
                                                  }
                                                >
                                                  {setting?.selectedUser
                                                    ?.find(
                                                      (user) =>
                                                        user._id ===
                                                        form.getFieldValue([
                                                          "store",
                                                          name,
                                                          "teammember",
                                                        ])
                                                    )
                                                    ?.services_id?.map((el) => {
                                                      return (
                                                        <Select.Option
                                                          value={el?._id}
                                                          key={el?._id}
                                                        >
                                                          {el?.servicename}
                                                        </Select.Option>
                                                      );
                                                    })}
                                                </Select>
                                              </Form.Item>
                                              <Form.Item
                                                {...restField}
                                                name={[name, "durations"]}
                                                label={
                                                  <span className="text-[#0F0F47] text-sm">
                                                    Service Durations (mins)
                                                  </span>
                                                }
                                              >
                                                <Input
                                                  disabled
                                                  value={Object.values(
                                                    form.getFieldValue([
                                                      "store",
                                                      name,
                                                      "durations",
                                                    ]) || {}
                                                  ).join(", ")}
                                                  className="pl-4 border"
                                                  style={{ height: 40 }}
                                                />
                                              </Form.Item>
                                              <Form.Item
                                                {...restField}
                                                name={[name, "start_time"]}
                                                label={
                                                  <span className="text-[#0F0F47] text-sm">
                                                    Time
                                                  </span>
                                                }
                                                rules={[
                                                  {
                                                    required: true,
                                                    message:
                                                      "Field is required!",
                                                  },
                                                ]}
                                              >
                                                <Select
                                                  size="large"
                                                  placeholder="Please select"
                                                  disabled={
                                                    !setting?.selectedUser?.some(
                                                      (user) =>
                                                        user._id ===
                                                        form.getFieldValue([
                                                          "store",
                                                          name,
                                                          "teammember",
                                                        ])
                                                    )
                                                  }
                                                >
                                                  {setting?.selectedUser
                                                    ?.find(
                                                      (user) =>
                                                        user._id ===
                                                        form.getFieldValue([
                                                          "store",
                                                          name,
                                                          "teammember",
                                                        ])
                                                    )
                                                    ?.availability?.map(
                                                      (el, index) => {
                                                        return (
                                                          <Select.Option
                                                            value={el}
                                                            key={index}
                                                          >
                                                            {el}
                                                          </Select.Option>
                                                        );
                                                      }
                                                    )}
                                                </Select>
                                              </Form.Item>
                                              {key >= 1 && (
                                                <div className="flex flex-row items-start -mt-2 mb-4 xgap-2">
                                                  <Button
                                                    onClick={() => remove(name)}
                                                    className="!m-0 justify-between !items-center text-red-500"
                                                  >
                                                    <CgRemove />
                                                  </Button>
                                                </div>
                                              )}
                                            </div>
                                            <Divider />
                                          </div>
                                        )
                                      )}
                                      <div>
                                        <Form.Item>
                                          <Button
                                            onClick={() => add()}
                                            className="!m-0 flex  !items-center text-[#2943D6] ml-4"
                                          >
                                            <IoMdAdd />
                                            <span className="text-blue-600">
                                              {" "}
                                              Add More
                                            </span>
                                          </Button>
                                        </Form.Item>
                                      </div>
                                    </>
                                  );
                                }}
                              </Form.List>
                            </div>
                          )}
                          <div className="custom-card">
                            <div className="card-header">
                              <h4 className="card-title">Appointment Notes</h4>
                            </div>
                            <div className="card-body">
                              <div className="form-row">
                                <div className="form-group col-md-12">
                                  <TextArea
                                    rows={4}
                                    placeholder="Client notes"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <div>
                      <button
                        type="button"
                        className="bg-[#e3b04b] light mr-3 p-2.5 text-white font-semibold rounded"
                        data-dismiss="modal"
                        onClick={handleCloseModal}
                      >
                        Close
                      </button>
                      {appointment?.isFetching ? (
                        <LoadingButton />
                      ) : (
                        <button
                          type="submit"
                          className="bg-[#1b1a17] hover:bg-[#cf7500] p-2.5 text-white font-semibold rounded"
                        >
                          Add Appointment
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <Modal
        title={
          <h1 className="text-[#030229] text-lg py-2">Add a new client</h1>
        }
        width={800}
        open={isClientModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <CreateClientForm onCancel={handleCancel} />
      </Modal>
    </>
  );
};

export default AddNewAppointmentForm;
