import React, { useEffect, useState } from "react";
import { DatePicker, Select, Spin } from "antd";
import dayjs from "dayjs";
import { axiosRequest } from "../../../api";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const Appointment_List_URL = "appointment";

const AppointmentTables = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDates, setSelectedDates] = useState([
    dayjs().startOf("month").format(dateFormat),
    dayjs().endOf("month").format(dateFormat),
  ]);

  useEffect(() => {
    if (selectedDates || selectedStatus) {
      setIsLoading(true);
      axiosRequest
        .get(
          `${Appointment_List_URL}?start_date=${selectedDates[0]}&end_date=${
            selectedDates[1]
          }${
            ![null, ""]?.includes(selectedStatus)
              ? `&status=${selectedStatus}`
              : ""
          }`
        )
        .then((response) => {
          const result = response.data.data;
          setTableData(result);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error.message);
          setIsLoading(false);
        });
    }
  }, [selectedDates, selectedStatus]);

  const handleDateChange = (dates) => {
    if (dates) {
      const formattedDates = dates.map((date) => date.format(dateFormat));
      setSelectedDates(formattedDates);
    }
  };

  return (
    <div className="h-full w-full relative">
      {isLoading && (
        <div className="bg-black opacity-25 h-full w-full absolute">
          <div className="flex items-center justify-center mt-20">
            <Spin tip="Loading" size="large" />
          </div>
        </div>
      )}
      <div className="card-body">
        <div className="flex items-center justify-between mb-4">
          <Select
            size="large"
            onChange={(value) => setSelectedStatus(value)}
            defaultValue={""}
            style={{ minWidth: 200 }}
          >
            <Select.Option value={""} default>
              All
            </Select.Option>
            <Select.Option value={"pending"}>Pending</Select.Option>
            <Select.Option value={"done"}>Completed</Select.Option>
            <Select.Option value={"cancelled"}>Cancelled</Select.Option>
          </Select>
          <RangePicker
            size="large"
            allowClear={false}
            value={[dayjs(selectedDates[0]), dayjs(selectedDates[1])]}
            format={dateFormat}
            onChange={handleDateChange}
          />
        </div>
        <div className="table-responsive">
          <table id="table-to-xls" className="display table-hover w-full">
            <thead>
              <tr className="border-b">
                <th className="py-6">Date </th>
                <th className="py-6">Time</th>
                <th className="py-6">Client</th>
                <th className="py-6">Team Member</th>
                <th className="py-6">Services</th>
                <th className="py-6">Status</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(tableData) &&
                tableData?.map((item) => (
                  <tr key={item._id} className="border-b">
                    <td className="py-3 capitalize">
                      {item?.appointment_date}
                    </td>
                    <td className="py-3 capitalize">{`${item?.start?.slice(
                      10
                    )} - ${item?.end?.slice(10)}`}</td>
                    <td className="py-3">{`${item?.telephone?.firstname} ${item?.telephone?.lastname}`}</td>
                    <td className="py-3">{`${item?.teammember?.firstname} ${item?.teammember?.lastname}`}</td>
                    <td className="py-3 capitalize">
                      {item?.services?.length > 0
                        ? item?.services
                            ?.map((el) => el?.servicename)
                            ?.join(", ")
                        : item?.service_id?.servicename}
                    </td>
                    <td className="py-3">
                      <span
                        className={
                          item?.appointment_status === "PENDING"
                            ? "badge light badge-success capitalize"
                            : item?.appointment_status === "DONE"
                            ? "badge light badge-info capitalize"
                            : item?.appointment_status === "CANCELLED"
                            ? "badge light badge-warning capitalize"
                            : ""
                        }
                      >
                        {item?.appointment_status}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AppointmentTables;
